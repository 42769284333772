<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create') }} {{ $t('campaign_group') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          :segmentation="segmentation"
          :step="isEditPage ? 'package' : 'campaign'"
        />
      </div>
    </div>
    <!-- CAMPAIGN LOADER -->
    <div v-if="campaignLoader" class="text-center">
      <Loader
        :size="'big'"
      />
    </div><!-- END CAMPAIGN LOADER -->
    <template v-else>
      <!-- PACKAGE FORM -->
      <div class="mt-3" v-if="!isEditPage || (isEditPage && segmentation)">
        <!-- HEADING -->
        <section class="my-4">
          <div class="col-lg-8 mx-auto text-center">
            <p>{{ $t('configure_campaign_groups_for_segmentation') }}</p>
          </div>
        </section> <!-- END HEADING -->
        <section class="">
          <div class="col-lg-8 mx-auto mb-5 position-relative">
            <ProgramaticCampaignNavigation
              :campaign="campaign"
              :segmentation="segmentation"
              step="package"
            />
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="" class="default-form">
                  <!-- NAME -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('group_name') }}*</label>
                    <input type="text" class="form-control" :placeholder="$t('group_name')" v-model.trim="form.name" />
                  </div> <!-- END NAME -->
                  <!-- OBJECTIVE -->
                  <div class="form-group col-lg-4 mb-3" v-if="1 == 2">
                    <label class="form-label fw-bold">{{ $t('group_goal') }}*</label>
                    <div class="custom-select">
                      <select
                        class="form-control"
                        v-model.trim="form.objective"
                      >
                        <option value="" disabled>{{ $t('select') }}</option>
                        <option value="CONVERSION">{{ $t('objective_type.conversion') }}</option>
                        <option value="RETARGETING">{{ $t('objective_type.retargeting') }}</option>
                      </select>
                    </div>
                  </div> <!-- END OBJECTIVE -->
                  <!-- DATES -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('group_duration') }}*</label>
                    <div class="d-lg-flex">
                      <div class="d-flex col-lg-6">
                        <!-- START DATE -->
                        <div class="col-lg-6">
                          <DateTimeInput
                            v-model.trim="form.start_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('start')"
                            :readonly="segmentation && segmentation.has_started"
                          />
                        </div> <!-- END START DATE -->
                        <!-- END DATE -->
                        <div class="col-lg-6">
                          <DateTimeInput
                            v-model.trim="form.end_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('end')"
                          />
                        </div> <!-- END END DATE -->
                      </div>
                      <div class="mt-3 mt-lg-2 text-center text-lg-start" v-if="campaign && (!isEditPage || segmentation.status === 'PENDING')">
                        <Button
                          class="btn btn-primary btn-xs"
                          @click="setCampaignDatesAsPackageDates"
                        >
                          {{ $t('use_same_period_as_campaign') }}
                        </Button>
                      </div>
                    </div>
                    <p
                      class="font-size-xSmall fw-bold mt-3"
                      v-if="campaign"
                    >
                      {{ $t('campaign_duration_period.0') }} {{ campaign.start_date | formatDate }}
                      <span v-if="campaign.end_date">{{ $t('campaign_duration_period.1') }} {{ campaign.end_date | formatDate }}, {{ $t('campaign_duration_period.3') }}</span>
                      <span v-else> {{ $t('campaign_duration_period.2') }}, {{ $t('campaign_duration_period.4') }}</span>
                    </p>
                    <p
                      class="font-size-xSmall fw-bold mt-2 alert-message-card"
                      v-if="form.start_date && (campaign.end_date && form.end_date) && isPackagePeriodDifferentThanCampaignPeriod"
                    >
                      {{ $t('campaign_group_period_less_than_campaign_period') }}
                    </p>
                    <p
                      class="font-size-xSmall fw-bold mt-2 error-message-card"
                      v-if="form.start_date && !isPackageStartDateValid"
                    >
                      {{ $t('group_start_validation') }}
                    </p>
                    <p
                      class="font-size-xSmall fw-bold mt-2 error-message-card"
                      v-if="form.end_date && !isPackageEndDateValid"
                    >
                      {{ $t('group_end_validation') }}
                    </p>
                    <p
                      class="font-size-xSmall fw-bold mt-2 error-message-card"
                      v-if="form.start_date && form.end_date && isPackageEndDateBeforeStartDate"
                    >
                      {{ $t('end_date_not_before_start_date') }}
                    </p>
                  </div> <!-- END DATES -->
                  <div class="form-group mb-3">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        v-model="isCustomBid"
                        @change="handleCustomBid"
                      >
                      <label class="form-check-label" for="flexSwitchCheckDefault">
                        <div class="bid-options">
                          <div v-if="!isCustomBid">
                            <p class="fw-700 mb-0 font-size-small">{{ $t('set_bid_automatically') }}</p>
                            <p class="font-size-xSmall">{{ $t('desc_set_bid_automatically') }}</p>
                          </div>
                          <div v-else>
                            <p class="fw-700 mb-0 font-size-small">{{ $t('set_my_own_bid') }}</p>
                            <p class="font-size-xSmall">{{ $t('desc_set_my_own_bid') }}</p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <!-- AMOUNT -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('group_budget') }}*</label>
                    <Money
                      class="form-control mb-3"
                      v-model.trim="form.amount"
                      v-bind="money"
                    />
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="col-12 col-lg-6">
                        <p class="font-size-xSmall fw-bold mb-0">
                          {{ $t('campaign_budget') }}: {{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}
                        </p>
                      </div>
                      <div class="col-12 col-lg-6 text-lg-end d-flex align-items-center justify-content-center justify-content-lg-end" v-if="campaign && (!isEditPage || segmentation.status === 'PENDING')">
                        <Button
                          class="btn btn-primary btn-xs me-2"
                          @click="setCampaignAmountAsPackageAmount"
                        >
                          {{ $t('use_same_budget_as_campaign') }}
                        </Button>
                      </div>
                    </div>
                    <p class="font-size-xSmall fw-bold error-message-card mt-3" v-if="activePackagesSumAmount > campaign.amount">
                      {{ $t('total_budget_of_active_campaign_groups_not_exceed_campaign_value') }} {{ activePackagesSumAmount / 100 | formatMoney(campaign.advertiser.currency) }}
                    </p>
                  </div><!-- END AMOUNT -->
                  <!-- CPC TARGET AMOUNT -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('cpc_goal') }}* <span v-b-tooltip.hover.html :title="tooltips.cpc_goal"><i class="fa fa-info-circle font-size-regular"></i></span></label>
                    <Money
                      class="form-control mb-3"
                      v-model.trim="form.cpc_target_amount"
                      v-bind="money"
                      :disabled="!isCustomBid"
                    />
                    <p class="font-size-xSmall fw-bold">
                      {{ $t('campaign_budget') }}: {{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}
                    </p>
                    <p class="font-size-xSmall fw-bold error-message-card" v-if="form.cpc_target_amount !== '' && form.cpc_target_amount < 3.5">
                      {{ $t('minimum_cpc_goal_value') }}
                    </p>
                  </div><!-- END CPC TARGET AMOUNT -->
                  <!-- CPM TARGET AMOUNT -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('cpm_goal') }}* <span v-b-tooltip.hover.html :title="tooltips.cpm_goal"><i class="fa fa-info-circle font-size-regular"></i></span></label>
                    <Money
                      class="form-control mb-3"
                      v-model.trim="form.cpm_target_amount"
                      v-bind="money"
                      :disabled="!isCustomBid"
                    />
                    <p class="font-size-xSmall fw-bold">
                      {{ $t('campaign_budget') }}: {{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}
                    </p>
                    <p class="font-size-xSmall fw-bold error-message-card" v-if="form.cpm_target_amount !== '' && form.cpm_target_amount < 12">
                      {{ $t('minimum_cpm_goal_value') }}
                    </p>
                  </div><!-- END CPM TARGET AMOUNT -->
                  <!-- ACTIONS -->
                  <div class="text-center mt-3">
                    <!-- MESSAGES -->
                    <div
                      class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                      v-if="loader"
                    >
                      <p
                        class="mb-0"
                      >
                        {{ $t('this_action_may_take_a_few_minutes') }}
                      </p>
                    </div> <!-- END MESSAGES -->
                    <Button
                      class="btn btn-primary"
                      @click="submit"
                      :loading="loader"
                      :disabled="!isFormComplete"
                    >
                      {{ isEditPage ? this.$t('save_group') : this.$t('create_group') }}
                    </Button>
                  </div> <!-- END ACTION -->
                </form>
              </div>
            </div>
          </div>
        </section>
      </div><!-- END PACKAGE FORM -->
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import CampaignPackagesService from '@/modules/campaigns/services/campaign-packages-service'
import { getMoneyFormat } from '@/utils'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'
import ProgramaticCampaignNavigation from '@/components/campaigns/Programatic/ProgramaticCampaignNavigation'

import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  mixins: [variables],
  components: {
    Button,
    CancelCampaignUpsertModal,
    DateTimeInput,
    Loader,
    Money,
    ProgramaticCampaignNavigation
  },
  data () {
    return {
      campaign: '',
      campaignLoader: false,
      isCustomBid: false,
      defaultCPC: 3.5,
      defaultCPM: 12,
      form: {
        campaign_id: '',
        name: '',
        start_date: '',
        end_date: '',
        // objective: '',
        cpm_target_amount: 12,
        cpc_target_amount: 3.5,
        amount: ''
      },
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      noEndDate: false,
      segmentation: ''
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  created () {
    this.getCampaign()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Active packages sum amount
     */
    activePackagesSumAmount () {
      if (!this.campaign) return 0
      const filtered = this.campaign.packages.filter(item => {
        return item.status !== 'PAUSED'
      })
      const amount = filtered.reduce(
        (acc, cur) => {
          return acc + cur.amount
        }, 0
      )

      return this.isEditPage ? amount : amount + parseInt(this.form.amount * 100)
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'packages.edit'
    },
    /**
     * Is form complete
     */
    isFormComplete () {
      if (
        !this.form.campaign_id ||
        !this.form.name ||
        // !this.form.objective ||
        !this.form.start_date ||
        !this.form.end_date ||
        parseFloat(this.form.cpm_target_amount) < 12 ||
        parseFloat(this.form.cpc_target_amount) < 3.5 ||
        parseFloat(this.form.amount) <= 0 ||
        !this.isPackageStartDateValid ||
        !this.isPackageEndDateValid ||
        this.isPackageEndDateBeforeStartDate ||
        this.activePackagesSumAmount > this.campaign.amount
      ) {
        return false
      }

      return true
    },
    /**
     * Is package period different than campaign period
     */
    isPackagePeriodDifferentThanCampaignPeriod () {
      const start = moment(this.form.start_date)
      const end = moment(this.form.end_date)
      const campaignStart = moment(this.campaign.start_date)
      const campaignEnd = moment(this.campaign.end_date)
      return !start.isSame(campaignStart) || !end.isSame(campaignEnd)
    },
    /**
     * Is package start date valid
     */
    isPackageStartDateValid () {
      const start = moment(this.form.start_date)
      const campaignStart = moment(this.campaign.start_date)
      if (this.form.start_date && this.campaign.end_date) {
        const campaignEnd = moment(this.campaign.end_date)
        return start.isSameOrAfter(campaignStart) && start.isBefore(campaignEnd)
      }
      return start.isSameOrAfter(campaignStart)
    },
    /**
     * Is package end date valid
     */
    isPackageEndDateValid () {
      const end = moment(this.form.end_date)
      const campaignStart = moment(this.campaign.start_date)
      if (this.campaign.end_date && this.form.end_date) {
        const campaignEnd = moment(this.campaign.end_date)
        return end.isSameOrBefore(campaignEnd) && end.isAfter(campaignStart)
      }
      return end.isAfter(campaignStart)
    },
    /**
     * Is package end date before start date
     */
    isPackageEndDateBeforeStartDate () {
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'packages.create' ? this.$t('create_group') : this.$t('edit_group')
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get campaign
     */
    async getCampaign () {
      this.campaignLoader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.cId)
        if (campaign.status === 'FINISHED') return this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
        this.campaign = campaign
        this.form.campaign_id = campaign.id
        this.money = getMoneyFormat(campaign.advertiser.currency)
        if (this.isEditPage) this.setPackage()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.campaignLoader = false
    },
    /**
     * handle custom bid
     */
    handleCustomBid () {
      this.form.cpc_target_amount = 3.5
      this.form.cpm_target_amount = 12
    },
    /**
     * Set campaign amount as amount of package
     */
    setCampaignAmountAsPackageAmount () {
      if (!this.campaign) return
      this.form.amount = this.campaign.amount / 100
    },
    /**
     * Set campaign dates as dates of package
     */
    setCampaignDatesAsPackageDates () {
      if (!this.campaign) return
      this.form.start_date = this.campaign.start_date
      this.form.end_date = this.campaign.end_date
      // this.noEndDate = this.campaign.no_end_date
    },
    /**
     * Set package
     */
    setPackage () {
      this.segmentation = this.campaign.packages.find(item => {
        return item.id === this.$route.params.id
      })
      if (!this.segmentation) return

      this.form.name = this.segmentation.name
      // this.form.objective = this.segmentation.objective
      this.form.start_date = this.segmentation.start_date
      this.form.end_date = this.segmentation.end_date
      this.form.amount = this.segmentation.amount / 100
      this.form.cpm_target_amount = this.segmentation.cpm_target_amount / 100
      this.form.cpc_target_amount = this.segmentation.cpc_target_amount / 100
      // this.noEndDate = this.segmentation.no_end_date
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))
        // Parse amount
        form.amount = parseInt(form.amount * 100)
        form.cpc_target_amount = parseInt(form.cpc_target_amount * 100)
        form.cpm_target_amount = parseInt(form.cpm_target_amount * 100)
        // if (this.noEndDate) form.end_date = '2024-07-31T00:00:00.000-03:00'

        let segmentation = null
        if (!this.segmentation) {
          segmentation = await CampaignPackagesService.createCampaignPackage(form)
        } else {
          segmentation = await CampaignPackagesService.updateCampaignPackage(this.segmentation.id, form)
        }
        this.setCurrentCampaign({ campaign: '' })
        this.$router.push({ name: 'packages.show', params: { cId: this.campaign.id, id: segmentation.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .programatic-campaign-navigation {
      position: absolute;
      top: 0;
      left: -100px;
      z-index: 2;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
</style>
